import axios from "axios";
import { getToken } from "./auth";

//LOCAL SERVER
  //  export const baseURL = "http://localhost:8000";

//UAT SERVER
//  export const baseURL = "https://test-backend.palmatravel.tech";

//LIVE SERVER
//  export const baseURL = "https://jbtravel-backend.palmatravel.tech";

//CANTOS SERVER
// export const baseURL = "https://cantos-backend.palmatravel.tech";


//PORTAL SERVER
//  export const baseURL = "https://portal-backend.palmatravel.tech";

//DREAMWORLD SERVER
  // export const baseURL = "https://dreamworld-backend.palmatravel.tech";

  //TARA TRAVEL SERVER
  // export const baseURL = "https://taratravel-backend.palmatravel.tech";

 //TARA TRAVEL SERVER
 //  export const baseURL = "https://laraf-backend.palmatravel.tech";


  //BANGO TRAVEL SERVER
  export const baseURL = "https://bango-backend.palmatravel.tech";


const api = axios.create({
  baseURL: baseURL + "/api",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { api };
